import React from 'react';
import CheckoutReviewPage from 'rev.sdk.js/Templates/CheckoutReviewPage';
import withPageEntry from '../../withPageEntry';
import * as CustomCheckout from '../../Utils/CustomCheckout';

function CheckoutReview(props) {
  return (
    <CheckoutReviewPage
      {...props}
      {...CustomCheckout.getExtraReviewPageProps()}
    />
  );
}

export default withPageEntry(CheckoutReview);
